import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Squares2X2Icon,
  IdentificationIcon,
  SparklesIcon,
  SwatchIcon,
  UserGroupIcon,
  KeyIcon,
  LifebuoyIcon,
  ChartBarSquareIcon,
  PuzzlePieceIcon,
  UsersIcon,
  HomeIcon,
  Square3Stack3DIcon,
  ChatBubbleLeftRightIcon, SignalIcon, FolderOpenIcon,
} from '@heroicons/react/24/outline';
import { cn } from '@/lib/utils';
import AdminMenuItemGroup from '@/components/Navigation/Admin/AdminMenuItemGroup.jsx';
import AdminSubMenuItem from '@/components/Navigation/Admin/AdminSubMenuItem.jsx';
import AdminSubMenu from '@/components/Navigation/Admin/AdminSubMenu.jsx';
import AdminMenuItem from '@/components/Navigation/Admin/AdminMenuItem.jsx';
import AdminMenu from '@/components/Navigation/Admin/AdminMenu.jsx';
import { AdminMenuProvider } from '@/contexts/AdminMenuContext.jsx';
import usePermission from '@/components/Hooks/usePermission';
import {
  AdminLayoutAdminUserHubUsers as UserAdminRoute,
  AdminLayoutAdminUserHubRoles as RolesAdminRoute,
  AdminLayoutAdminConferenceHubMyAdboardsIndex as MyAdboardsRoute,
  AdminLayoutAdminConferenceHubMyAdboardsDrafts as MyAdboardsDraftsRoute,
  AdminLayoutAdminConferenceHubMyAdboardsArchive as MyAdboardsArchiveRoute,
  AdminLayoutAdminOperationsHubAseReportingIndex as AseReportingRoute,
  AdminLayoutAdminUserHubIndex as UserHubRoute,
  AdminLayoutAdminConferenceHubIndex as ConferenceHubRoute,
  AdminLayoutAdminOperationsHubIndex as OperationsHubRoute,
  AdminLayoutAdminAnalyticsHubUserInsights as UserInsightsHubRoute,
  AdminLayoutAdminAnalyticsHubPlatformInsightsIndex as PlatformInsightsHubRoute,
  AdminLayoutAdminAnalyticsHubAdboardInsightsIndex as AdboardInsightsHubRoute,
  AdminLayoutAdminAnalyticsHubIndex as AnalyticsHubRoute,
  AdminLayoutAdminFaqHubFaq as FaqHubRoute,
  AdminLayoutAdminFaqHubCategory as CategoryHubRoute, AdminLayoutAdminOperationsHubPulseIndex,
  AdminLayoutAdminConferenceHubContentManagerIndex as ContentManagerRoute,
  AdminLayoutAdminConferenceHubContentManagerFolder as ContentManagerFolderRoute,
} from '@/routePaths.gen.js';
import useRole from '@/components/Hooks/useRole';

export default function NavAdminSidebar({ className = '' }) {
  const [userViewPermissions] = usePermission('users.view');
  const [rolesViewPermissions] = usePermission('roles.view');
  const [adboardsEditPermissions] = usePermission('adboards.edit');
  const [isAdmin] = useRole('admin');

  const [activeGroup, setActiveGroup] = useState(null);

  return (
    <nav
      className={cn(
        'sticky left-0 top-0 flex h-screen w-80 md:min-w-64 lg:min-w-80 max-w-[320px] flex-grow flex-col items-start justify-between bg-gradient-to-t from-[#FF585D] from-[-9%] to-[#FFC100] to-[106%]',
        className,
      )}
    >
      <AdminMenuProvider
        activeGroup={activeGroup}
        setActiveGroup={setActiveGroup}
      >
        <AdminMenu>
          {(userViewPermissions || rolesViewPermissions) && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={UserGroupIcon}
                title="User Hub"
                route={UserHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'User Hub'}
              >
                <AdminSubMenu>
                  {userViewPermissions && (
                    <AdminSubMenuItem
                      icon={IdentificationIcon}
                      title="User Verwaltung"
                      to={UserAdminRoute}
                    />
                  )}
                  {rolesViewPermissions && (
                    <AdminSubMenuItem
                      icon={SwatchIcon}
                      title="Rollen Verwaltung"
                      to={RolesAdminRoute}
                    />
                  )}
                </AdminSubMenu>
              </AdminMenuItemGroup>
            </AdminMenuItem>
          )}
          {adboardsEditPermissions && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={SparklesIcon}
                title="Conference Hub"
                route={ConferenceHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'Conference Hub'}
              >
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={Squares2X2Icon}
                    title="Meine Adboards"
                    to={MyAdboardsRoute}
                    additionalRoutes={[
                      MyAdboardsDraftsRoute,
                      MyAdboardsArchiveRoute,
                    ]}
                  />
                  <AdminSubMenuItem
                    icon={FolderOpenIcon}
                    title="Content Manager"
                    to={ContentManagerRoute}
                    additionalRoutes={[
                      ContentManagerFolderRoute,
                    ]}
                  />
                </AdminSubMenu>
              </AdminMenuItemGroup>
            </AdminMenuItem>
          )}
          {adboardsEditPermissions && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={ChartBarSquareIcon}
                title="Analytics Hub"
                route={AnalyticsHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'Analytics Hub'}
              >
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={PuzzlePieceIcon}
                    title="Adboard Insights"
                    to={AdboardInsightsHubRoute}
                  />
                </AdminSubMenu>
                <AdminSubMenuItem
                  icon={HomeIcon}
                  title="Platform Insights"
                  to={PlatformInsightsHubRoute}
                />

                <AdminSubMenuItem
                  icon={UsersIcon}
                  title="User Insights"
                  to={UserInsightsHubRoute}
                />
              </AdminMenuItemGroup>
            </AdminMenuItem>
          )}
          {isAdmin && (
          <AdminMenuItem>
            <AdminMenuItemGroup
              icon={KeyIcon}
              title="Operations Hub"
              route={OperationsHubRoute}
              setActive={setActiveGroup}
              active={activeGroup === 'Operations Hub'}
            >
              <AdminSubMenu>
                <AdminSubMenuItem
                  icon={LifebuoyIcon}
                  title="ASE Monitoring"
                  to={AseReportingRoute}
                />
              </AdminSubMenu>
              <AdminSubMenu>
                <AdminSubMenuItem
                  icon={SignalIcon}
                  title="Pulse"
                  to={AdminLayoutAdminOperationsHubPulseIndex}
                />
              </AdminSubMenu>
              <AdminSubMenu>
                <AdminSubMenuItem
                  icon={ChatBubbleLeftRightIcon}
                  title="FAQ Verwaltung"
                  to={FaqHubRoute}
                />
              </AdminSubMenu>
              <AdminSubMenu>
                <AdminSubMenuItem
                  icon={Square3Stack3DIcon}
                  title="Kategorie Verwaltung"
                  to={CategoryHubRoute}
                />
              </AdminSubMenu>
            </AdminMenuItemGroup>
          </AdminMenuItem>
          )}
        </AdminMenu>
      </AdminMenuProvider>
    </nav>
  );
}

NavAdminSidebar.propTypes = {
  className: PropTypes.string,
};
